@import url("https://use.typekit.net/nfp1cpw.css");

html {
  height: 100%;
  display: block;
}

body {
  background: #f7eae2;
  min-height: 100%;
  color: #000;
  background-image: url(/bg.svg);
  background-repeat: repeat;
  margin: 0;
  font-family: 'sofia-pro', sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: scroll;
}

.container-fluid {
	padding-bottom: 100px !important;
}

.nav {
  width: 100%;
  height: auto;
  position: top;
  background: #f7eae2;
  overflow: auto;
}

.nav * {
  float: left;
  padding: 11px;
  text-align: center;
  text-decoration: none;
  width: 33.33%;
  color: #000;
  font-family: 'sofia-pro-condensed', sans-serif;
  font-weight: 500;
  font-size: 28px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 1px solid #fd5357;
}

.works {
  border-right: 1px solid #fd5357;
  border-left: 1px solid #fd5357;
}

.nav *:hover {
  background: #fd5357;
  color: #f7eae2;
  text-decoration: none;
}

.nav-text-active {
  color: #fd5357;
  text-decoration: none;
}

.intro {
  margin: 4% 15%;
  height: 100%;
  padding-bottom: 10%;
}

.intro-two {
  margin: 2% 15%;
  height: 100%;
  overflow: visible;
}

.intro-two hr {
  border: 1px solid #000;
  margin-top: 70px;
}

.introduction {
  width: 48%;
  font-weight: 400;
  font-size: 1.5vw;
  -webkit-animation: fade-in-top 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.6s both;
  animation: fade-in-top 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.6s both;
}

.intro-header {
  font-family: 'bely-display', sans-serif;
  font-weight: 400;
  font-size: 6.6vw;
  margin-bottom: 30px;
  -webkit-animation: fade-in-top 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-top 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.intro-header span {
  color: #fd5357;
}

.skills-title {
  font-family: 'bely-display', sans-serif;
  font-weight: 400;
  font-size: 2.2vw;
}

.skill-icon {
  color: #fd5357;
  font-size: 72px;
  margin: 20px auto;
}

.square {
  margin: 30px auto;
  padding: 15px;
  width: 210px;
  height: 210px;
  background: #f7eae2;
  border: 1px solid #fd5357;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
}

.skill-set {
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  margin: 30px auto;
}

.skill-set ul {
  list-style: none;
  display: inline-block;
  text-align: left;
  padding: 0;
}

.copyright {
  box-sizing: border-box;
  height: 18vh;
  text-align: center;
  font-weight: 500;
}

.box {
  box-sizing: content-box;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  width: 78%;
  height: auto;
  background: #f5e4d9;
  text-align: center;
  padding: 50px;
}

.contact-box {
  box-sizing: border-box;
  width: 78%;
  padding: 55px 50px;
  margin: 10vh auto;
  background: #f7eae2;
  border: 1px solid #fd5357;
  font-weight: 400;
  font-size: 1.7vw;
}

.contact-header {
  font-family: 'bely-display', sans-serif;
  font-weight: 400;
  font-size: 3.3vw;
  margin-bottom: 10%;
}

.contact-socmed {
  display: none;
}

.email {
  color: #000;
  text-decoration: underline;
}

.email:hover {
  color: #fd5357;
}

.form-input {
  width: 90%;
  background: #f7eae2;
  color: #000;
  border: 1px solid #fd5357;
  font-family: 'sofia-pro-condensed', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 1;
  padding: 5px 10px;
  text-align: center;
  letter-spacing: 1px;
  margin: 8px auto;
}

.form-input::placeholder {
  color: #fd5357;
  text-transform: uppercase;
}

.form-input:focus {
  outline: none;
}

.form-input:focus::placeholder {
  color: transparent;
}

.btn-form {
  width: 90%;
  background: #fd5357;
  color: #f7eae2;
  border: 1px solid #fd5357;
  font-family: 'sofia-pro-condensed', sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  padding: 8px 10px;
  text-align: center;
  letter-spacing: 1px;
}

.btn-form:hover {
  background: #f7eae2;
  color: #fd5357;
  border: 1px solid #fd5357;
}

.btn-form:focus {
  outline: none;
}

.footer {
  width: 100%;
  height: auto;
  background: #f7eae2;
  border-top: 1px solid #fd5357;
  overflow: auto;
}

.connect {
  float: left;
  width: 80%;
  padding: 12px 0px;
  font-family: 'sofia-pro-condensed', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-right: 1px solid #fd5357;
}

.connect-two {
  display: none;
}

.social-media {
  float: left;
  width: 20%;
  padding: 12px 0px;
}

.social-links {
  text-decoration: none;
  color: #000;
  font-size: 2vw;
  padding: 0 0.9vw;
}

.social-links:hover {
  text-decoration: none;
  color: #fd5357;
}

.projects {
  font-family: 'sofia-pro-condensed', sans-serif;
  font-weight: 500;
  font-size: 28px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 4%;
}

.project {
  height: 100%;
  overflow: auto;
}

.project img {
  max-width: 60%;
  height: auto;
}

.project-number {
  font-family: 'bely-display', sans-serif;
  font-weight: 400;
  font-size: 28px;
  display: inline;
  padding: 20px;
  text-decoration: underline;
  cursor: pointer;
}

.project-number:hover {
  color: #fd5357;
  text-decoration: line-through;
}

.project-number-active {
  color: #fd5357;
  text-decoration: line-through;
}

.project-header {
  font-family: 'sofia-pro-condensed', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 24px;
}

.project-title {
  font-family: 'sofia-pro-condensed', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
}

.project-text {
  width: 50%;
  text-align: left;
  font-family: 'sofia-pro', sans-serif;
  font-weight: 300;
  font-style: normal;
  margin-left: auto;
}

.project-link {
  display: block;
  font-family: 'sofia-pro-condensed', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: #000;
  text-decoration: underline;
}

.project-link:hover {
  color: #000;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 900px) {
  .square {
    width: 170px;
    height: 170px;
    font-size: 16px;
    /* padding: 10px;
    margin: 10px auto;
    line-height: 16px; */
  }

  .skill-icon {
    font-size: 66px;
    margin: 12px auto;
  }
}

@media screen and (max-width: 700px) {
  .social-media {
    width: 0%;
  }

  .social-links {
    display: none;
  }

  .footer {
    height: 2.6em;
  }

  .connect {
    display: none;
  }

  .connect-two {
    display: block;
    width: 100%;
    padding: 6px 0px;
    font-family: 'sofia-pro-condensed', sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .form {
    display: none;
  }

  .contact-box {
    padding: 8vh 5vw;
    font-size: 3.4vw;
    margin: 0 auto;
    margin-top: 12vh;
  }

  .contact-header {
    font-size: 8.8vw;
  }

  .contact-socmed {
    display: block;
  }

  .socmed-links {
    text-decoration: none;
    color: #000;
    padding-right: 3vw;
    font-size: 4vw;
  }

}

@media screen and (max-width: 640px) {
  .nav * {
    font-size: 4.5vw;
    padding: 6px;
  }

  .intro {
    margin: 12% 5%;
    padding-bottom: 10px;
  }

  .intro-header {
    font-size: 9.3vw;
    margin-bottom: 20px;
  }

  .introduction {
    width: 75%;
    font-weight: 400;
    font-size: 4vw;
  }

  .intro-two {
    margin: 2%;
    height: 100%;
    overflow: auto;
  }

  .square {
    width: 120px;
    height: 120px;
    padding: 10px;
    margin: 10px auto;
    line-height: 16px;
  }

  .skill-icon {
    font-size: 40px;
    margin: 7px auto;
  }

  .skills-title {
    font-size: 6.2vw;
  }

  .skill-set {
    font-size: 18px;
  }

  .box {
    width: 80%;
    padding: 25px;
    padding-bottom: 65px;
    margin-top: 25px;
  }

  .project img {
    max-width: 100%;
    height: auto;
  }

  .project-text {
    width: 90%;
    margin: 0 auto;
    font-size: 3.3vw;
  }

  .project-title {
    font-size: 3.9vw;
  }

  .project-link {
    font-size: 3.9vw;
  }

  .projects {
    font-size: 4.4vw;
    margin-top: 5%;
  }

  .project-number {
    font-size: 4.8vw;
    padding: 0 2.5vw;
  }

  .copyright {
    font-size: 4.2vw;
    height: 5vh;
  }
}




/* ----------------------------------------------
 * Generated by Animista on 2020-9-21 17:7:16
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-top
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

